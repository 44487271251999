<template>
    <v-sheet>
        <v-simple-table dense>
            <template v-slot:default>
                
                <thead>
                    <tr>
                        <th class="primary lighten-3 text-center"><span class="body-2 font-weight-black">Antecedente</span></th>
                        <th class="primary lighten-3 text-center"><span class="body-2 font-weight-black">Personal</span></th>
                        <th class="primary lighten-3 text-center"><span class="body-2 font-weight-black">Familiar</span></th>
                        <th class="primary lighten-3 text-center"><span class="body-2 font-weight-black">Especifica</span></th>
                    </tr>
                </thead>

                <tbody>
                    <tr class="mb-3" v-for="item in datos" :key="item.id">
                        
                        <td width="45%" class="textoarriba tablaDiag" >
                            <div>
                                {{item.antecedente}}
                            </div>
                        </td>

                        <td width="5%" class="textoarriba tablaDiag" >
                            <div class="text-center">
                                <v-icon color="primary" v-if="item.personal">mdi-check-bold</v-icon>
                            </div>
                        </td>

                        <td width="5%" class="textoarriba tablaDiag" >
                            <div class="text-center">
                                <v-icon color="primary" v-if="item.familiar">mdi-check-bold</v-icon>
                            </div>
                        </td>
                        
                        <td width="45%" class="textoarriba tablaDiag" >
                            <div>
                                {{item.especifica}}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-sheet>
</template>

<script>

export default {
    props:{
        datos:Array
    }

}
</script>

<style>
.textoarriba{
    vertical-align: top;

}
.tablaDiag{
    border: 1px solid  #ECECEC;
    border-spacing: 0.5rem;
    border-collapse: collapse;
}
</style>