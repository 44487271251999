<template>
    <div>
        <!--
        <v-alert
            type="warning"
            dense
            text
            border="left"
        >
            Esta valoración incial fué hecha hace mas de 7 días. Es necesario actualizar
        </v-alert>
        -->

        <v-toolbar short dense dark color="primary" flat>
            <v-toolbar-title>Antecedentes patológicos</v-toolbar-title>
        </v-toolbar>
        <valoracion-patologicos
            class="mb-5 px-10 pt-5"
            :selectData="antSelect.d1"
            :internamiento='internamiento'
        />


        <v-toolbar short dense color="primary" dark flat>
            <v-toolbar-title>Valoración inicial</v-toolbar-title>
        </v-toolbar>

        <v-sheet elevation="1" class="mb-2 mx-1">
            <v-row class="pt-5" no-gutters>
                <v-col class="px-1" xs=6 sm=6 md=4 lg=3 xl=2 cols="12">
                    <v-switch
                        v-model="datosPato.cariesDental"
                        label="Caries dental"
                        item-value=true
                        item-disabled=false
                    ></v-switch>
                </v-col>

                <v-col class="px-1" xs=6 sm=6 md=4 lg=3 xl=2 cols="12">
                    <v-switch
                        v-model="datosPato.piezasCompletas"
                        label="Piezas completas"
                        item-value=true
                        item-disabled=false
                    ></v-switch>
                </v-col>

                <v-col class="px-1" xs=6 sm=6 md=4 lg=3 xl=2 cols="12">
                    <v-text-field
                        v-model="datosPato.fechaUltimaRevision"
                        rounded
                        filled
                        clearable
                        label="Última revisión"
                    ></v-text-field>
                </v-col>

            </v-row>


            <v-row class="pt-5" no-gutters>
                <v-col class="px-1" xs=6 sm=6 md=4 lg=3 xl=2 cols="12">
                    <v-select
                        v-model="datosPato.esquemaVacunacion"
                        rounded
                        filled
                        clearable
                        :items="vacunacionSelect"
                        label="Esquema de vacunación"
                    ></v-select>
                </v-col>

                <v-col class="px-1" xs=6 sm=6 md=4 lg=3 xl=2 cols="12">
                    <v-text-field
                        v-model="datosPato.fechaIniciaEsquema"
                        rounded
                        filled
                        clearable
                        label="Fecha inicio esquema"
                    ></v-text-field>
                </v-col>
            </v-row>
            <!-- Datos de somatometría -->
            <v-row class="pt-5" no-gutters>
                <v-col class="px-1" xs=6 sm=6 md=4 lg=3 xl=2 cols="12" v-for="itm in somatoData" :key="itm.id">
                    <v-text-field
                    v-model="datosPato[itm.modelo]"
                    type="number"
                    filled
                    rounded
                    clearable
                    :label="itm.etiqueta"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row class="pt-5" no-gutters>
                <v-col class="px-1" cols="12">
                    <v-text-field
                        v-model="datosPato.recienNacido"
                        rounded
                        filled
                        clearable
                        label="Valoración del recien nacido"
                    ></v-text-field>
                </v-col>
                <v-col class="px-1" cols="12">
                    <v-text-field
                        v-model="datosPato.gineco"
                        rounded
                        filled
                        clearable
                        label="Valoración gineco-obstétrica"
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-sheet>

        <v-toolbar short dense color="primary" dark  flat>
            <v-toolbar-title>Valoración por aparatos y sistemas</v-toolbar-title>
        </v-toolbar>
        <v-sheet elevation="1" class="pb-2 px-3">
            <v-row class="pt-5" no-gutters>
                <v-col class="pt-5" xs=12 sm=12 md=6 lg=6 xl=6 cols="12" v-for="itm in selectData" :key="itm.id">
                    <v-select
                        class="px-1"
                        v-model="datosPato[itm.modelo]"
                        :items="antSelect[itm.select]"
                        :label="itm.etiqueta"
                        multiple
                        chips
                        rounded
                        outlined
                        item-text="antecedente"
                        item-value="antecedente"
                    />

                </v-col>
            </v-row>

            <v-text-field
                v-model="datosPato.otros"
                filled
                rounded
                clearable
                label="OTROS (separe por coma)"
            />

            <v-btn
                color="success"
                @click="guardaAntecedentes"
                :loading="aregaAntLoading"
            >
                Guardar
            </v-btn>
        </v-sheet>

    </div>
</template>

<script>

import ValoracionPatologicos from '@/components/hospital/enfe/valinicial/AntecedentesPatologicos'

export default {
    async mounted(){
        try {
            var datos = await this.$http({
                method:'GET',
                url: '/enfermeria/catalogoAntecedentes',
            })
            var i;
            for (i = 1; i <= 12; i++) {
                this.antSelect['d'+i]=datos.data.filter(an => an.tipo == i)
            }
            this.cargaAntecedentes()
        } catch(err){
            console.log(err)
            this.$store.dispatch('security/revisaError',err)
        }
    },
    components:{
        'valoracion-patologicos':ValoracionPatologicos,
        //'antecedentes-todos':AntecedentesTodos,
    },

    props:{
        estaFecha: String,
        internamiento: Number,
    },
    
    data:()=>({
        aregaAntLoading:false,
        vacunacionSelect:[
            {value:1, text:'Completa'},
            {value:2, text:'Incompleta'},
            {value:9, text:'Se deconoce'},
        ],

        selectData:[
            {id:1, modelo:'cardioRespiratorio', etiqueta:'Sistema CARDIORESPIRATORIO', select:'d2'},
            {id:2, modelo:'digestivo', etiqueta:'Sistema DIGESTIVO', select:'d3'},
            {id:3, modelo:'urinario', etiqueta:'Sistema URINARIO', select:'d4'},
            {id:4, modelo:'femenino', etiqueta:'Sistema GENITAL FEMENINO', select:'d5'},
            {id:5, modelo:'masculino', etiqueta:'Sistema GENITAL MASCULINO', select:'d6'},
            {id:6, modelo:'endocrino', etiqueta:'Sistema ENDÓCRINO', select:'d7'},
            {id:7, modelo:'nervioso', etiqueta:'Sistema NERVIOSO', select:'d8'},
            {id:8, modelo:'tegumentario', etiqueta:'Sistema TEGUMENTARIO', select:'d9'},
            {id:9, modelo:'musculoEsqueletico', etiqueta:'Sistema MUSCULO ESQUELÉTICO', select:'d10'},
            {id:10, modelo:'hematoLinfatico', etiqueta:'Sistema LINFÁTICO', select:'d11'},
            {id:11, modelo:'psicologicos', etiqueta:'ASPECTOS PSICOLÓGICOS', select:'d12'},
        ],
        somatoData:[
            {id:1, modelo:'fr', etiqueta:"Frecuencia Resp (X')"},
            {id:2, modelo:'taSis', etiqueta:'Pres. Arterial mm/hg (Sist)'},
            {id:3, modelo:'taDia', etiqueta:'Pres. Arterial mm/hg (Dias)'},
            {id:4, modelo:'pam', etiqueta:'Presión arterial media (mm/hg)'},
            {id:5, modelo:'glucemia', etiqueta:'Glucemia capilar (mg/dl)'},
            {id:6, modelo:'temp', etiqueta:'Temperatura ºC'},
            {id:7, modelo:'fc', etiqueta:"Frecuencia cardiaca (X')"},
            {id:8, modelo:'spo2', etiqueta:'SPO2 (%)'},
        ],

        datosPato:{
            cariesDental:false,
            piezasCompletas:false,
            fechaUltimaRevision:'',
            esquemaVacunacion:3,
            fechaIniciaEsquema:'',

            fr:0, taSis:0, taDia:0, pam:0,
            glucemia:0, temp:0, fc:0, spo2:0,

            cardioRespiratorio:[],
            digestivo:[],
            urinario:[],
            femenino:[],
            masculino:[],
            endocrino:[],
            nervioso:[],
            tegumentario:[],
            musculoEsqueletico:[],
            hematoLinfatico:[],
            psicologicos:[],
            otros:'',
        },

        /*
        * 1: Antecedentes patológicos
        * 2: Sistema cardio respiratorio
        * 3: Sistema digestivo
        * 4: Sistema urinario
        * 5: Sistema genital femenino
        * 6: Sistema genital masculino
        * 7: Sistema endócrino
        * 8: Sistema nervioso
        * 9; Sistema tegumentario
        * 10: Sistema musculo esquelético
        * 11: Sistema linfático
        * 12: Psicológicos
        */
       
        antSelect:{
            d1:[],d2:[],d3:[],d4:[],d5:[],d6:[],
            d7:[],d8:[],d9:[],d10:[],d11:[],d12:[],
        },
        antLoading:{
            d1:false,d2:false,d3:false,d4:false,d5:false,d6:false,
            d7:false,d8:false,d9:false,d10:false,d11:false,d12:false,
        },

        panelActivo:0,
        
        
    }),
    methods:{
        async guardaAntecedentes(){
            let params = this.datosPato
            params.internamiento = this.internamiento
            params.temp = parseFloat(params.temp)
            try{
                let ap = await this.$http({
                    method:'POST',
                    url:'/enfermeria/guardaAntecedentes',
                    data:params,
                })

                if (ap.data.estado == true){
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Actualizado',
                        text: 'Antecedentes actualziados exitosamente',
                    })
                } else {
                    console.log(ap.data.root)
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }

            }catch(err){
                console.log(err)
                this.$store.dispatch('security/revisaError',err)
                this.loading = false
            }
        },
        async cargaAntecedentes(){
            let params = {
                internamiento:this.internamiento
            }
            try{
                let vl = await this.$http({
                    method:'GET',
                    url:'/enfermeria/listAntecedentes',
                    params:params
                })

                //console.log(vl.data)
                if(vl.data.estado == true){
                    this.datosPato = vl.data.root
                    this.datosPato.cardioRespiratorio=vl.data.root.cardioRespiratorio.split(',')
                    this.datosPato.digestivo=vl.data.root.digestivo.split(',')
                    
                    this.datosPato.urinario=vl.data.root.urinario.split(',')
                    this.datosPato.femenino=vl.data.root.femenino.split(',')
                    this.datosPato.masculino=vl.data.root.masculino.split(',')
                    this.datosPato.endocrino=vl.data.root.endocrino.split(',')
                    this.datosPato.nervioso=vl.data.root.nervioso.split(',')
                    this.datosPato.tegumentario=vl.data.root.tegumentario.split(',')
                    this.datosPato.musculoEsqueletico=vl.data.root.musculoEsqueletico.split(',')
                    this.datosPato.hematoLinfatico=vl.data.root.hematoLinfatico.split(',')
                    this.datosPato.psicologicos=vl.data.root.psicologicos.split(',')
                    
                } 

            }catch(err){
                console.log(err)
                this.$store.dispatch('security/revisaError',err)
                this.loading = false
            }
        }
    },

}
</script>

<style>

</style>