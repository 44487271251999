<template>
    <div>
        <v-sheet elevation="1" class="pb-1">
            <v-btn color="primary" small outlined @click="dialogAgregaAnt=true" class="ma-1">
                <v-icon left>
                    mdi-playlist-plus
                </v-icon>
                Agregar
            </v-btn>
            <v-alert v-if="datosPato.length == 0" text border="left" type="warning" class="mx-5">
                No se han registrado antecedentes patológicos
            </v-alert>

            <list-ante
                v-if="datosPato.length > 0"
                :datos="datosPato"
            />
        </v-sheet>

        <v-dialog
            v-model="dialogAgregaAnt"
            width="500"
            persistent
        >
            <v-card>
                <v-card-title
                    class="headline grey lighten-2"
                >
                    Nuevo antecedente
                </v-card-title>
                <v-card-text>
                    <v-form
                        class="mt-2"
                        
                        ref="agregaAntForm"
                    >
                        <v-row class="pt-5" justify="center" no-gutters>
                            <v-col class="px-1" xs=12 sm=12 md=12 lg=12 xl=12 cols="12">
                                <v-select
                                    v-model="antecedenteDatos.antecedente"
                                    rounded
                                    filled
                                    clearable
                                    :items="selectData"
                                    item-text='antecedente'
                                    item-value='antecedente'
                                    label="Antecedente"
                                ></v-select>
                            </v-col>

                            <v-col class="px-1" xs=12 sm=12 md=6 lg=6 xl=6 cols="12">
                                <v-switch
                                    v-model="antecedenteDatos.personal"
                                    label="Personal"
                                    @change="antecedenteDatos.familiar = !antecedenteDatos.personal"
                                ></v-switch>
                            </v-col>

                            <v-col class="px-1" xs=12 sm=12 md=6 lg=6 xl=6 cols="12">
                                <v-switch
                                    v-model="antecedenteDatos.familiar"
                                    label="Familiar"
                                    @change="antecedenteDatos.personal = !antecedenteDatos.familiar"
                                ></v-switch>
                            </v-col>

                            <v-col class="px-1" xs=12 sm=12 md=12 lg=12 xl=12 cols="12">
                                <v-text-field
                                    v-model="antecedenteDatos.especifica"
                                    rounded
                                    filled
                                    clearable
                                    label="Especificación"
                                ></v-text-field>
                            </v-col>
                        </v-row>


                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="error"
                        text
                        @click="cancelAgregaAnt"
                    >
                        Cancelar
                    </v-btn>
                    
                    <v-btn
                        color="success"
                        text
                        @click="agregaAnt"
                        :loading="aregaAntLogin"
                    >
                        Agregar
                    </v-btn>
                </v-card-actions>
            </v-card>
            
        </v-dialog>
    </div>
</template>

<script>
import ListAnte from '@/components/hospital/enfe/valinicial/ListaAnte'
export default {
    mounted(){
        this.cargaAntecedentes()
    },
    components:{
        'list-ante':ListAnte,
    },
    props:{
        selectData:Array,
        internamiento:Number
    },
    data:()=>({
        datosPato:[],
        dialogAgregaAnt:false,
        aregaAntLogin:false,
        antecedenteDatos:{
            antecedente:null,
            personal:false,
            familiar:false,
            especifica:''
        },
    }),

    methods:{
        async agregaAnt(){
            this.aregaAntLogin=true
            
            let params=this.antecedenteDatos
            params.internamiento = this.internamiento

            try{
                let apa = await this.$http({
                    method:'POST',
                    url:'/enfermeria/guardaAntecedentesPatologicos',
                    data:params
                })

                if (apa.data.estado == true){
                    this.$refs.agregaAntForm.reset()
                    this.aregaAntLogin=false
                    this.dialogAgregaAnt=false
                    this.cargaAntecedentes()
                } else {
                    this.aregaAntLogin=false
                    console.log(apa.data.root)
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }

            } catch(err){
                console.log(err)
                this.aregaAntLogin=false
                this.$store.dispatch('security/revisaError',err)
            }
            
            

            //console.log(this.antecedenteDatos)
            
            //this.$refs.agregaAntForm.reset()
            
        },

        cancelAgregaAnt(){

            this.$refs.agregaAntForm.reset()
            this.dialogAgregaAnt=false
        },

        async cargaAntecedentes(){
            let params = {
                internamiento:this.internamiento
            }
            try{
                let vl = await this.$http({
                    method:'GET',
                    url:'/enfermeria/listAntecedentesPatologicos',
                    params:params
                })

                if(vl.data.estado == true){
                    this.datosPato = vl.data.root
                } 

            }catch(err){
                console.log(err)
                this.$store.dispatch('security/revisaError',err)
                this.loading = false
            }
        }

        

    },
}
</script>

<style>

</style>